<template>
  <div>
    <smallbutton toplabel="Import Badge File" @click="Add()"/>
    <!-- ACCT NOTE MODAL -->
      <actionmodal ref="mymodal"> 
        <template v-slot:body>
            <label>Upload Badge File</label>
            <!-- <div class="horizFlexCenter">
                        <input type="checkbox"  v-model="isARUpload" />
                        <label class="entryLabel" for="">AR Student Upload</label>
            </div> -->
            

            <imagecapture v-if="!pleaseWait" ref="img" :allowFileSelect='allowFileSelect' :showImage="showImage" :fileAccept="fileAccept" :allowCapture="allowCapture" @gotimage="gotImage_file" fileSelectText="Click to Select File" />

            <div v-if="pleaseWait">
                <label class="pleasewait">Importing. Please Wait...</label>
            </div>
            <div>
                <smallbutton  @click="close()" toplabel="Close" :disabled="pleaseWait"/>
            </div>
            
            <div>
                <label class="responsemessage">{{responseMessage}}</label>
            </div>
          
        </template>
      </actionmodal>
    </div>
</template>

<script>
import {mapFields} from 'vuex-map-fields'

import imagecapture from '../controls/ImageCapture.vue'
import datamethods from '../../datamethods.js'
import actionmodal from '../controls/actionmodal.vue'
import smallbutton from '../controls/button.vue'
export default {
    name:'uploadfile',
    components:{
        imagecapture,
        actionmodal,
        smallbutton
    },
    mixins:[ datamethods],
    data(){
        return{
            allowFileSelect:true,
            allowCapture:false,
            capturedFile:null,
            capturedFileURL:null,
            fileNameText:'',
            fileAccept:'*',
            showImage:false,
            message:'',

            isARUpload:false,
            responseMessage:'',
            pleaseWait:false
        }
    },
    computed:{
        ...mapFields([
      
            'username',
          
        ]),
        
    },
    methods:{
        Add(){
            this.responseMessage=''
            this.isARUpload=false
            this.$refs.mymodal.openModal()
        },
        gotImage_file(file,url){
            console.log('gotimg' + url)
            this.capturedFile=file
            this.capturedFileURL=url
            
            this.storeImage("file", file, file.name,"file")
        },
        async storeImage(filename,file, name,type){

            var postParams = new FormData();

            postParams.append(filename,file,name);
            
            postParams.append('filename',name);
            postParams.append('isARUpload',true)//this.isARUpload
           postParams.append('sendLogToEmail',this.username)
           this.pleaseWait=true
            var response = await this.callAPI(postParams,'savefile')
            //handle response
            if(response.Successful){
                //this.getBadgeDetail(); 
                this.responseMessage="Uploaded " + name + '. Please check your email for detailed import results.'
                this.pleaseWait=false
            }else{
                this.responseMessage = response.Message
                this.pleaseWait=false
            }
             
        },
        close(){
            this.$emit('refresh')
            this.$refs.mymodal.closeModal()
        },
    }
}
</script>

<style>

</style>